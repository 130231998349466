<template>
  <SourceUserStyled>
    <h4>Source User</h4>
    <AutoForm :model.sync="user" :modelName="'User'" :filterFields="filterFields" :disabledFields="disabledFields" class="userForm" />
  </SourceUserStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { AutoForm } from '@common/components'
import chroma from 'chroma-js'

const SourceUserStyled = styled('div')`
  grid-area: sourceUser;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${p => chroma(p.theme.colors.black).alpha(0.5).css()};
  height: calc(100% - 2rem);
  border-radius: 8px;
  h4 {
    margin-top: 0.2rem;
  }
  .userForm {
    label {
      justify-self: flex-start;
      padding-left: 0;
    }
    input {
      width: 90%;
    }
  }
`

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    SourceUserStyled,
    AutoForm,
  },
  data() {
    return {
      userId: null,
      errors: [],
      disabledFields: ['id', 'email', 'username'],
      filterFields: ['name', 'firstName', 'lastName', 'createdAt'],
    }
  },
}
</script>
