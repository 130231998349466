<template>
  <ModelCreater
    :title="title"
    :loading="$apollo.loading"
    :errors="errors"
    :canCreate="canCreate"
    :canCancel="true"
    :showPrevArrow="false"
    @create="create"
    @cancel="cancel"
  >
    <DuplicateUserStyled v-if="user">
      <SourceUser :user="user" />
      <TargetUser @selectedEntities="selectedEntities" :sourceUser="user" />
      <SelectUserPermissions
        :user="user"
        :subsidiaryMemberships="subsidiaryMemberships"
        @copyPermissions="copyPermissions"
        :key="componentKey"
      />
      <AffectedUsers :users="affectedUserData" />
    </DuplicateUserStyled>
  </ModelCreater>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import ModelCreater from '@/components/misc/ModelCreater'
import SourceUser from './DuplicateUser/SourceUser'
import TargetUser from './DuplicateUser/TargetUser'
import SelectUserPermissions from './DuplicateUser/SelectUserPermissions'
import AffectedUsers from './DuplicateUser/AffectedUsers'
import CopyDashboardMixin from './DuplicateUser/CopyDashboardMixin.js'
import { FlashMessages } from '@common/singletons'
import USER_QUERY from '#/graphql/user/list.gql'
import SUBSIDIARY_MEMBERSHIPS from '#/graphql/user/subsidiaryMembership/list.gql'

const DuplicateUserStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    'sourceUser targetUsers  affectedUsers'
    'sourcePermissions targetUsers affectedUsers';
  grid-auto-rows: 21rem;
  grid-gap: 1rem;
  align-items: center;
  padding: 0.5rem;
`

import UPDATE_MANY_TARGET_USER_PERMISSIONS from '#/graphql/misc/updateManyTargetUserPermissions.gql'
import UPDATE_MANY_TARGET_USER_WIDGET_TYPES from '#/graphql/misc/updateManyTargetUserWidgetTypes.gql'
import UPDATE_MANY_TARGET_USER_ORG_MEMBERSHIPS from '#/graphql/misc/updateManyTargetUserOrgMemberships.gql'
import UPDATE_MANY_TARGET_USER_SUBSIDIARY_MEMBERSHIPS from '#/graphql/misc/updateManyTargetUserSubsidiaryMemberships.gql'
import UPDATE_MANY_TARGET_USER_ASSET_DIMENSIONS from '#/graphql/misc/updateManyTargetUserAssetDimensions.gql'

export default {
  mixins: [CopyDashboardMixin],
  components: {
    DuplicateUserStyled,
    AffectedUsers,
    SelectUserPermissions,
    ModelCreater,
    SourceUser,
    TargetUser,
  },
  data() {
    return {
      errors: [],
      user: null,
      permissions: [],
      affectedUserData: [],
      selectedPermissions: [],
      subsidiaryMemberships: [],
      isSubmitLoading: false,
      subsidiaryMembershipsToCopy: [],
      componentKey: 0,
    }
  },
  computed: {
    title() {
      return `Duplicate User`
    },
    isPermissionsSelected() {
      return !!this.permissions.find(p => p.isSelected === true)
    },
    canCreate() {
      return this.affectedUserData.length > 0 && this.isPermissionsSelected && !this.isSubmitLoading
    },
  },
  methods: {
    cancel() {
      this.$router.push({
        name: 'users',
      })
    },
    copyPermissions(permissions) {
      this.permissions = permissions
    },
    organizationUserData(entities) {
      const orgUsers = entities
        .map(entity => {
          const memberships = get(entity, 'memberships', [])
          return memberships.map(membership => {
            return get(membership, 'user', {})
          })
        })
        .reduce((acc, curVal) => acc.concat(curVal), [])
      return uniqBy(orgUsers, 'id')
    },
    subsidiaryUserData(entities) {
      const subsidiaryUsers = entities
        .map(entity => {
          return get(entity, 'memberships', []).map(membership => {
            return membership.user
          })
        })
        .reduce((acc, curVal) => acc.concat(curVal), [])
      return uniqBy(subsidiaryUsers, 'id')
    },
    async create() {
      this.isSubmitLoading = true
      this.selectedPermissions = this.permissions.filter(p => p.isSelected === true)
      const promises = []
      if (this.selectedPermissions.some(e => e.name === 'permissions')) {
        const permissionsToCopy = this.user.userPermissions.map(({ permission }) => permission.id)
        promises.push(this.copyUserPermissions(permissionsToCopy))
      }
      if (this.selectedPermissions.some(e => e.name === 'widgetTypes')) {
        const widgetTypesToCopy = this.user.userWidgetTypes.map(({ widgetType }) => widgetType.name)
        promises.push(this.copyUserWidgetTypes(widgetTypesToCopy))
      }
      if (this.selectedPermissions.some(e => e.name === 'assetDimensions')) {
        const assetDimesionsToCopy = this.user.userAssetDimensions.map(({ assetDimension }) => assetDimension.name)
        promises.push(this.copyUserAssetDimensions(assetDimesionsToCopy))
      }
      if (this.selectedPermissions.some(e => e.name === 'dashboards')) {
        if (this.user?.dashboards && this.affectedUserData.length > 0) {
          this.user.dashboards.forEach(d => {
            this.affectedUserData.forEach(e => promises.push(this.copyDashboard(d.id, e.id, true)))
          })
        }
      }
      if (this.selectedPermissions.some(e => e.name === 'organizations')) {
        const organizationMembershipsToCopy = this.user.memberships.map(membership => {
          return {
            role: membership.role,
            id: membership.organization.id,
          }
        })
        await this.copyOrganizationMemberships(organizationMembershipsToCopy)
      }
      if (this.selectedPermissions.some(e => e.name === 'subsidiaries')) {
        const subsidiaryMembershipsToCopy = this.subsidiaryMemberships.map(({ subsidiary }) => {
          const membership = subsidiary.memberships[0]
          if (membership.length < 1) {
            return []
          }
          return {
            id: subsidiary.id,
            role: membership.role,
          }
        })
        promises.push(this.copySubsidiaryMemberships(subsidiaryMembershipsToCopy))
      }
      await Promise.all(promises)
      this.isSubmitLoading = false
      this.permissions = []
      this.componentKey += 1
      // query all affected users so the cache is updated
      const reloadPromises = []
      this.affectedUserData.forEach(user => {
        reloadPromises.push(
          this.$apollo.query({
            query: USER_QUERY,
            variables: {
              where: {
                id: user.id,
              },
            },
          }),
        )
      })
      await Promise.all(reloadPromises)
      this.$emit('reload')
    },
    selectedEntities(entities) {
      if (entities && entities.length > 0) {
        if (get(entities[0], '__typename', '') === 'User') {
          this.affectedUserData = entities
        } else if (get(entities[0], '__typename', '') === 'Organization') {
          this.affectedUserData = this.organizationUserData(entities)
        } else if (get(entities[0], '__typename', '') === 'Subsidiary') {
          this.affectedUserData = this.subsidiaryUserData(entities)
        }
      } else {
        this.affectedUserData = []
      }
    },
    async copyUserPermissions(permissionsToCopy) {
      const userIds = this.affectedUserData.map(a => a.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_MANY_TARGET_USER_PERMISSIONS,
          variables: {
            data: {
              userIds,
              permissions: permissionsToCopy,
            },
          },
        })
        FlashMessages.$emit('success', `Successfully copied persmissions to selected users`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', new Error(`Permissions could not be copied`))
        this.errors.push(err)
      }
    },
    async copyUserWidgetTypes(widgetTypesToCopy) {
      const userIds = this.affectedUserData.map(a => a.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_MANY_TARGET_USER_WIDGET_TYPES,
          variables: {
            data: {
              userIds,
              widgetTypes: widgetTypesToCopy,
            },
          },
        })
        FlashMessages.$emit('success', `Successfully copied widget types to selected users`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', new Error(`Widget types could not be copied`))
        this.errors.push(err)
      }
    },
    async copyOrganizationMemberships(organizationMembershipsToCopy) {
      const userIds = this.affectedUserData.map(a => a.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_MANY_TARGET_USER_ORG_MEMBERSHIPS,
          variables: {
            data: {
              userIds,
              organizationMemberships: organizationMembershipsToCopy,
            },
          },
        })
        FlashMessages.$emit('success', `Successfully copied organization memberships to selected users`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', new Error(`Organization memberships could not be copied`))
        this.errors.push(err)
      }
    },
    async copySubsidiaryMemberships(subsidiaryMembershipsToCopy) {
      const userIds = this.affectedUserData.map(a => a.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_MANY_TARGET_USER_SUBSIDIARY_MEMBERSHIPS,
          variables: {
            data: {
              userIds,
              subsidiaryMemberships: subsidiaryMembershipsToCopy,
            },
          },
        })
        FlashMessages.$emit('success', `Successfully copied subsidiary memberships to selected users`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', new Error(`Subsidiary memberships could not be copied`))
        this.errors.push(err)
      }
    },
    async copyUserAssetDimensions(assetDimensionsToCopy) {
      const userIds = this.affectedUserData.map(a => a.id)
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_MANY_TARGET_USER_ASSET_DIMENSIONS,
          variables: {
            data: {
              userIds,
              assetDimensions: assetDimensionsToCopy,
            },
          },
        })
        FlashMessages.$emit('success', `Successfully copied assetDimensions to selected users`, {
          timeout: 3000,
        })
      } catch (err) {
        FlashMessages.$emit('error', new Error(`AssetDimensions could not be copied`))
        this.errors.push(err)
      }
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      skip() {
        return !this.$route.params.id
      },
    },
    subsidiaryMemberships: {
      query: SUBSIDIARY_MEMBERSHIPS,
      variables() {
        return {
          where: {
            userId: this.$route.params.id,
          },
        }
      },
      skip() {
        return !this.$route.params.id
      },
    },
  },
}
</script>
