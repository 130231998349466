<template>
  <AffectedUsersStyled>
    <h4>Affected Users</h4>
    <div class="wrapper" v-if="users && users.length > 0">
      <UserListStyled v-for="user in users" :key="user.id">
        <span>{{ user.email }}</span>
      </UserListStyled>
    </div>
    <div class="info" v-else>Select the Target Entities to see the Affected Users list Here!</div>
  </AffectedUsersStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter, buttonReset } from '@styles/mixins'
import chroma from 'chroma-js'

const AffectedUsersStyled = styled('div')`
  grid-area: affectedUsers;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${props => chroma(props.theme.colors.black).alpha(0.5).css()};
  height: calc(100% - 2rem);
  border-radius: 8px;
  h4 {
    margin-top: 0.2rem;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
    max-height: 35rem;
  }
  .info {
    padding: 2rem;
    display: flex;
    align-items: center;
    height: inherit;
    text-align: center;
  }
`
const UserListStyled = styled('button')`
  ${flexCenter}
  ${buttonReset}
  background: ${props => chroma(props.theme.colors.black).alpha(0.7).css()};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 1rem 0.5rem;
  width: 20rem;
  justify-content: flex-start;
  transition: background-color 0.1s linear;
  cursor: default;
`
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  components: {
    AffectedUsersStyled,
    UserListStyled,
  },
  computed: {},
  methods: {},
}
</script>
