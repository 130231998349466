var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AffectedUsersStyled', [_c('h4', [_vm._v("Affected Users")]), _vm.users && _vm.users.length > 0 ? _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.users, function (user) {
    return _c('UserListStyled', {
      key: user.id
    }, [_c('span', [_vm._v(_vm._s(user.email))])]);
  }), 1) : _c('div', {
    staticClass: "info"
  }, [_vm._v("Select the Target Entities to see the Affected Users list Here!")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }