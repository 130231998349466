var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('SelectUserEntitiesStyled', [_c('h4', [_vm._v("Select attributes to copy")]), _c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.selectableEntities, function (entity) {
    return _c('PermissionStyled', {
      key: entity.id,
      class: {
        enabled: entity.isSelected
      },
      on: {
        "click": function click($event) {
          return _vm.toggleEntity(entity);
        }
      }
    }, [_c('CheckIcon'), _c('span', [_vm._v(_vm._s(entity.name) + " (" + _vm._s(entity.count) + ")")])], 1);
  }), 1), _vm.message ? _c('div', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.away,
      expression: "away"
    }],
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.message))]) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }