var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SourceUserStyled', [_c('h4', [_vm._v("Source User")]), _c('AutoForm', {
    staticClass: "userForm",
    attrs: {
      "model": _vm.user,
      "modelName": 'User',
      "filterFields": _vm.filterFields,
      "disabledFields": _vm.disabledFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.user = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }