var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('TargetUserStyled', [_c('h4', [_vm._v("Target Entity Configuration")]), _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "type"
  }, [_c('span', [_vm._v(" Target Type ")]), _c('Multiselect', {
    attrs: {
      "placeholder": "Select Type",
      "options": _vm.targetTypes
    },
    on: {
      "select": _vm.setTargetType
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  })], 1), _c('div', {
    staticClass: "entity"
  }, [_c('span', [_vm._v(" Target Entities ")]), _c('Multiselect', {
    staticClass: "multiselect",
    attrs: {
      "track-by": "id",
      "label": "name",
      "placeholder": "Search Entities",
      "options": _vm.mappedEntities,
      "multiple": true,
      "loading": _vm.$apollo.loading,
      "internal-search": true,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 100,
      "limit": 3,
      "max-height": 600,
      "show-no-results": true,
      "hide-selected": false,
      "disabled": _vm.mappedEntities.length === 0,
      "preserve-search": true,
      "showNoResults": true
    },
    on: {
      "select": _vm.selectedEnities,
      "remove": _vm.removeEntities,
      "search-change": _vm.handleSearchChange
    },
    model: {
      value: _vm.selectedValue,
      callback: function callback($$v) {
        _vm.selectedValue = $$v;
      },
      expression: "selectedValue"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }