<template>
  <SelectUserEntitiesStyled v-if="user">
    <h4>Select attributes to copy</h4>
    <div class="wrapper">
      <PermissionStyled
        v-for="entity in selectableEntities"
        :key="entity.id"
        :class="{ enabled: entity.isSelected }"
        @click="toggleEntity(entity)"
      >
        <CheckIcon />
        <span>{{ entity.name }} ({{ entity.count }})</span>
      </PermissionStyled>
    </div>
    <div v-if="message" class="error" v-away="away">{{ message }}</div>
  </SelectUserEntitiesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { directive as onClickaway } from 'vue-clickaway'

import { flexCenter, buttonReset } from '@styles/mixins'
import { CheckIcon } from 'vue-feather-icons'

const SelectUserEntitiesStyled = styled('div')`
  grid-area: sourcePermissions;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${p => chroma(p.theme.colors.black).alpha(0.5).css()};
  height: calc(100% - 2rem);
  border-radius: 8px;
  h4 {
    margin-top: 0.2rem;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .error {
    font-size: 12px;
    color: ${props => props.theme.colors.red};
    background: ${({ theme }) => theme.colors.atomic.chart.marigoldYellow};
    padding: 5px;
    text-align: center;
  }
`
const PermissionStyled = styled('button')`
  ${flexCenter}
  ${buttonReset}
  background: ${props => chroma(props.theme.colors.white).alpha(0.1).css()};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  transition: background-color 0.1s linear;
  .feather {
    opacity: 0.1;
    color: ${p => p.theme.colors.black};
    margin-right: 0.25rem;
  }
  &:hover {
    .feather {
      color: ${p => p.theme.colors.archonBlue};
      opacity: 1;
    }
  }
  &.enabled {
    background: ${props => chroma(props.theme.colors.archonBlue).alpha(0.2).css()};
    .feather {
      color: ${p => p.theme.colors.white};
      opacity: 1;
    }
  }
`
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    subsidiaryMemberships: {
      type: Array,
      required: true,
    },
  },
  directives: {
    away: onClickaway,
  },
  data() {
    return {
      message: '',
      entities: [
        {
          name: 'permissions',
          isSelected: false,
        },
        {
          name: 'assetDimensions',
          isSelected: false,
        },
        {
          name: 'widgetTypes',
          isSelected: false,
        },
        {
          name: 'dashboards',
          isSelected: false,
        },
        {
          name: 'organizations',
          isSelected: false,
        },
        {
          name: 'subsidiaries',
          isSelected: false,
        },
      ],
    }
  },
  components: {
    SelectUserEntitiesStyled,
    PermissionStyled,
    CheckIcon,
  },
  computed: {
    userMapped() {
      const user = { ...this.user }
      const organizations = user.memberships.map(m => m.organization)
      const subsidiaries = this.subsidiaryMemberships
      return {
        permissions: user.permissions,
        assetDimensions: user.userAssetDimensions,
        widgetTypes: user.userWidgetTypes,
        dashboards: user.dashboards,
        organizations,
        subsidiaries,
      }
    },
    selectableEntities() {
      return this.entities.map(e => {
        const selectedEntity = this.userMapped[e.name]
        return {
          ...e,
          count: selectedEntity ? selectedEntity.length : 0,
        }
      })
    },
  },
  methods: {
    away() {
      this.message = ''
    },
    toggleEntity(entity) {
      if (entity.name === 'subsidiaries') {
        const hasOrg = this.entities.find(e => e.name === 'organizations')
        if (!hasOrg.isSelected) {
          this.message = 'You cannot select subsidiary without selecting Organization'
          return
        }
      }
      const found = this.entities.find(e => e.name === entity.name)
      found.isSelected = !found.isSelected
      if (found.name === 'organizations' && !found.isSelected) {
        const subsidiaries = this.entities.find(e => e.name === 'subsidiaries')
        subsidiaries.isSelected = false
      }
      this.$emit('copyPermissions', this.entities)
    },
  },
}
</script>
