<template>
  <TargetUserStyled>
    <h4>Target Entity Configuration</h4>
    <div class="wrapper">
      <div class="type">
        <span> Target Type </span>
        <Multiselect placeholder="Select Type" :options="targetTypes" v-model="value" @select="setTargetType" />
      </div>
      <div class="entity">
        <span> Target Entities </span>
        <Multiselect
          class="multiselect"
          track-by="id"
          label="name"
          placeholder="Search Entities"
          v-model="selectedValue"
          :options="mappedEntities"
          :multiple="true"
          :loading="$apollo.loading"
          :internal-search="true"
          :clear-on-select="false"
          :close-on-select="false"
          :options-limit="100"
          :limit="3"
          :max-height="600"
          :show-no-results="true"
          :hide-selected="false"
          :disabled="mappedEntities.length === 0"
          @select="selectedEnities"
          @remove="removeEntities"
          :preserve-search="true"
          :showNoResults="true"
          @search-change="handleSearchChange"
        />
      </div>
    </div>
  </TargetUserStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import Multiselect from 'vue-multiselect'
import USER_QUERY from '#/graphql/users/list.gql'
import ORGANIZATIONS_QUERY from '#/graphql/organizations/list.gql'
import SUBSIDIARIES_QUERY from '#/graphql/organizations/subsidiary/list.gql'
import chroma from 'chroma-js'
import { debounce } from 'lodash'

const TargetUserStyled = styled('div')`
  grid-area: targetUsers;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  background: ${props => chroma(props.theme.colors.black).alpha(0.5).css()};
  height: calc(100% - 2rem);
  border-radius: 8px;
  h4 {
    margin-top: 0.2rem;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 1rem;
  }
  .multiselect {
    margin-top: 10px;
    max-width: 25rem;
    .multiselect__content-wrapper {
      max-height: 350px !important;
    }
  }
`
export default {
  components: {
    TargetUserStyled,
    Multiselect,
  },
  props: {
    sourceUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      value: null,
      targetTypes: ['Users', 'Organizations', 'Subsidiaries'],
      selectedValue: [],
      selectedEntityValues: [],
      organizations: [],
      subsidiaries: [],
      searchQuery: '',
      selectedTargetType: '',
    }
  },

  computed: {
    mappedUsers() {
      const filteredUsers = this.users.filter(user => user.id !== this.sourceUser.id)
      return filteredUsers.map(user => ({
        ...user,
        name: user.email,
      }))
    },
    mappedEntities() {
      if (this.selectedTargetType === 'Users') {
        return this.mappedUsers
      } else if (this.selectedTargetType === 'Organizations') {
        return this.organizations
      } else {
        return this.subsidiaries
      }
    },
    queryVariables() {
      let where = {}
      if (this.searchQuery.length) {
        where = {
          search: this.searchQuery,
        }
      }
      return {
        where,
      }
    },
  },
  methods: {
    handleSearchChange: debounce(function (searchQuery) {
      this.searchQuery = searchQuery
    }, 300),
    setTargetType(type) {
      this.selectedValue = []
      this.selectedEnities('reset')
      this.selectedTargetType = type
    },
    selectedEnities(entities) {
      if (entities === 'reset') {
        this.selectedEntityValues = []
      } else {
        this.selectedEntityValues.push(entities)
      }
      this.$emit('selectedEntities', this.selectedEntityValues)
    },
    removeEntities(entities) {
      this.selectedEntityValues = this.selectedEntityValues.filter(value => value.id !== entities.id)
      this.$emit('selectedEntities', this.selectedEntityValues)
    },
  },
  apollo: {
    users: {
      query: USER_QUERY,
      variables() {
        return this.queryVariables
      },
    },
    organizations: {
      query: ORGANIZATIONS_QUERY,
    },
    subsidiaries: {
      query: SUBSIDIARIES_QUERY,
    },
  },
}
</script>
