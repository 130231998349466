var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ModelCreater', {
    attrs: {
      "title": _vm.title,
      "loading": _vm.$apollo.loading,
      "errors": _vm.errors,
      "canCreate": _vm.canCreate,
      "canCancel": true,
      "showPrevArrow": false
    },
    on: {
      "create": _vm.create,
      "cancel": _vm.cancel
    }
  }, [_vm.user ? _c('DuplicateUserStyled', [_c('SourceUser', {
    attrs: {
      "user": _vm.user
    }
  }), _c('TargetUser', {
    attrs: {
      "sourceUser": _vm.user
    },
    on: {
      "selectedEntities": _vm.selectedEntities
    }
  }), _c('SelectUserPermissions', {
    key: _vm.componentKey,
    attrs: {
      "user": _vm.user,
      "subsidiaryMemberships": _vm.subsidiaryMemberships
    },
    on: {
      "copyPermissions": _vm.copyPermissions
    }
  }), _c('AffectedUsers', {
    attrs: {
      "users": _vm.affectedUserData
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }